import React from "react";

import Manage from "./Manage";
import Organize from "./Organize";
import StayConnected from "./StayConnected";

import styles from "./Features.module.css";

const Features = () => (
  <div className={styles.Features}>
    <div className={styles.FeaturesTitle}>
      Why <span className={styles.FeaturesTitleHighlighted}>FitLab?</span>
    </div>
    <div className={styles.FeaturesText}>
      Start using FitLab right now and be sure that we will keep base
      functionality always for free and will keep improving it from day to day
    </div>
    <div className={styles.FeaturesBlocks}>
      <div className={styles.FeaturesBlock}>
        <div className={styles.FeaturesBlockImage}>
          <Organize />
        </div>
        <div className={styles.FeaturesBlockText}>
          Organize and keep your workout programs
        </div>
      </div>
      <div className={styles.FeaturesBlock}>
        <div className={styles.FeaturesBlockImage}>
          <StayConnected />
        </div>
        <div className={styles.FeaturesBlockText}>
          Stay connected and track the progress of your clients
        </div>
      </div>
      <div className={styles.FeaturesBlock}>
        <div className={styles.FeaturesBlockImage}>
          <Manage />
        </div>
        <div className={styles.FeaturesBlockText}>
          Manage nutrition plans, personal library, and more!
        </div>
      </div>
    </div>
  </div>
);

export default Features;
