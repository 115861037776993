import React from "react";

const Manage = () => (
  <svg
    width="176"
    height="171"
    viewBox="0 0 176 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.409 77.9978C175.409 96.5145 160.403 111.521 141.886 111.521C123.372 111.521 108.363 96.5145 108.363 77.9978C108.363 59.486 123.372 44.4749 141.886 44.4749C160.403 44.4749 175.409 59.486 175.409 77.9978Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.6104 18.734C85.6104 34.6047 72.7472 47.4679 56.8764 47.4679C41.0081 47.4679 28.1425 34.6047 28.1425 18.734C28.1425 2.86563 41.0081 -10 56.8764 -10C72.7472 -10 85.6104 2.86563 85.6104 18.734Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.7799 123.11C95.7799 149.56 74.3372 171 47.8899 171C21.4403 171 0 149.56 0 123.11C0 96.6605 21.4403 75.2202 47.8899 75.2202C74.3372 75.2202 95.7799 96.6605 95.7799 123.11Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.89 155.208H43.1105C33.76 145.858 28.5185 140.616 19.1656 131.263V23.5109C19.1656 19.5456 22.3814 16.3274 26.349 16.3274H131.707C135.677 16.3274 138.89 19.5456 138.89 23.5109V155.208Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.893 155.211H57.4799V121.688C57.4799 76.7263 93.929 40.2749 138.893 40.2749V155.211Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.8552 153.412H137.095V23.5109C137.095 20.5417 134.678 18.1232 131.707 18.1232H26.349C23.3798 18.1232 20.9614 20.5417 20.9614 23.5109V130.521L43.8552 153.412ZM138.89 157.004H43.1105C42.634 157.004 42.179 156.815 41.8414 156.48L17.8964 132.535C17.5588 132.197 17.3697 131.742 17.3697 131.263V23.5109C17.3697 18.5614 21.3996 14.5315 26.349 14.5315H131.707C136.659 14.5315 140.686 18.5614 140.686 23.5109V155.208C140.686 156.202 139.882 157.004 138.89 157.004Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9319 22.9124C34.9405 22.9124 34.136 22.1103 34.136 21.1165V11.5385C34.136 10.5472 34.9405 9.74268 35.9319 9.74268C36.9232 9.74268 37.7277 10.5472 37.7277 11.5385V21.1165C37.7277 22.1103 36.9232 22.9124 35.9319 22.9124Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.2988 22.9124C49.3075 22.9124 48.503 22.1103 48.503 21.1165V11.5385C48.503 10.5472 49.3075 9.74268 50.2988 9.74268C51.2902 9.74268 52.0947 10.5472 52.0947 11.5385V21.1165C52.0947 22.1103 51.2902 22.9124 50.2988 22.9124Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.6658 22.9124C63.6745 22.9124 62.8699 22.1103 62.8699 21.1165V11.5385C62.8699 10.5472 63.6745 9.74268 64.6658 9.74268C65.6571 9.74268 66.4616 10.5472 66.4616 11.5385V21.1165C66.4616 22.1103 65.6571 22.9124 64.6658 22.9124Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.0328 22.9124C78.0415 22.9124 77.2369 22.1103 77.2369 21.1165V11.5385C77.2369 10.5472 78.0415 9.74268 79.0328 9.74268C80.0241 9.74268 80.8287 10.5472 80.8287 11.5385V21.1165C80.8287 22.1103 80.0241 22.9124 79.0328 22.9124Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.3998 22.9124C92.4084 22.9124 91.6039 22.1103 91.6039 21.1165V11.5385C91.6039 10.5472 92.4084 9.74268 93.3998 9.74268C94.3911 9.74268 95.1956 10.5472 95.1956 11.5385V21.1165C95.1956 22.1103 94.3911 22.9124 93.3998 22.9124Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.767 22.9124C106.775 22.9124 105.971 22.1103 105.971 21.1165V11.5385C105.971 10.5472 106.775 9.74268 107.767 9.74268C108.758 9.74268 109.563 10.5472 109.563 11.5385V21.1165C109.563 22.1103 108.758 22.9124 107.767 22.9124Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.134 22.9124C121.142 22.9124 120.338 22.1103 120.338 21.1165V11.5385C120.338 10.5472 121.142 9.74268 122.134 9.74268C123.125 9.74268 123.93 10.5472 123.93 11.5385V21.1165C123.93 22.1103 123.125 22.9124 122.134 22.9124Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.1009 155.208L19.156 131.263H43.1009V155.208Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.1009 155.208L33.0369 145.144L43.1009 131.263V155.208Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4924 133.059L41.3051 150.874V133.059H23.4924ZM43.101 157.004C42.634 157.004 42.1767 156.822 41.8319 156.48L17.8869 132.535C17.3721 132.02 17.2212 131.249 17.4966 130.576C17.7744 129.906 18.4305 129.468 19.156 129.468H43.101C44.0947 129.468 44.8968 130.272 44.8968 131.263V155.208C44.8968 155.936 44.461 156.592 43.7906 156.87C43.5679 156.961 43.3332 157.004 43.101 157.004Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.3949 42.0634H59.872C58.8807 42.0634 58.0761 41.2589 58.0761 40.2676C58.0761 39.2738 58.8807 38.4717 59.872 38.4717H93.3949C94.3863 38.4717 95.1908 39.2738 95.1908 40.2676C95.1908 41.2589 94.3863 42.0634 93.3949 42.0634Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.126 56.4331H97.4991C96.5078 56.4331 95.7032 55.6285 95.7032 54.6372C95.7032 53.6435 96.5078 52.8413 97.4991 52.8413H122.126C123.118 52.8413 123.922 53.6435 123.922 54.6372C123.922 55.6285 123.118 56.4331 122.126 56.4331Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.9187 56.4331H64.661C63.6697 56.4331 62.8651 55.6285 62.8651 54.6372C62.8651 53.6435 63.6697 52.8413 64.661 52.8413H87.9187C88.9101 52.8413 89.7146 53.6435 89.7146 54.6372C89.7146 55.6285 88.9101 56.4331 87.9187 56.4331Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.0806 56.4331H35.9246C34.9333 56.4331 34.1288 55.6285 34.1288 54.6372C34.1288 53.6435 34.9333 52.8413 35.9246 52.8413H55.0806C56.0719 52.8413 56.8765 53.6435 56.8765 54.6372C56.8765 55.6285 56.0719 56.4331 55.0806 56.4331Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.184 70.8H71.8445C70.8532 70.8 70.0486 69.9954 70.0486 69.0041C70.0486 68.0104 70.8532 67.2083 71.8445 67.2083H98.184C99.1753 67.2083 99.9798 68.0104 99.9798 69.0041C99.9798 69.9954 99.1753 70.8 98.184 70.8Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.2641 70.8H35.9246C34.9333 70.8 34.1288 69.9954 34.1288 69.0041C34.1288 68.0104 34.9333 67.2083 35.9246 67.2083H62.2641C63.2554 67.2083 64.06 68.0104 64.06 69.0041C64.06 69.9954 63.2554 70.8 62.2641 70.8Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.2091 85.1669H59.872C58.8807 85.1669 58.0761 84.3648 58.0761 83.3711C58.0761 82.3797 58.8807 81.5752 59.872 81.5752H86.2091C87.2004 81.5752 88.0049 82.3797 88.0049 83.3711C88.0049 84.3648 87.2004 85.1669 86.2091 85.1669Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.294 85.1669H35.9246C34.9333 85.1669 34.1288 84.3648 34.1288 83.3711C34.1288 82.3797 34.9333 81.5752 35.9246 81.5752H50.294C51.2853 81.5752 52.0899 82.3797 52.0899 83.3711C52.0899 84.3648 51.2853 85.1669 50.294 85.1669Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.8469 99.5339H35.9246C34.9333 99.5339 34.1288 98.7317 34.1288 97.738C34.1288 96.7467 34.9333 95.9421 35.9246 95.9421H71.8469C72.8382 95.9421 73.6427 96.7467 73.6427 97.738C73.6427 98.7317 72.8382 99.5339 71.8469 99.5339Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.0555 113.903H35.9246C34.9333 113.903 34.1288 113.101 34.1288 112.107C34.1288 111.116 34.9333 110.312 35.9246 110.312H67.0555C68.0468 110.312 68.8514 111.116 68.8514 112.107C68.8514 113.101 68.0468 113.903 67.0555 113.903Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.531 94.8216C124.466 94.8216 124.401 94.8192 124.334 94.8096C123.348 94.7043 122.637 93.8183 122.744 92.8318C124.603 75.7518 112.039 70.7282 111.912 70.6803C110.985 70.3235 110.523 69.2867 110.877 68.36C111.234 67.4309 112.271 66.9712 113.198 67.3256C113.348 67.3854 128.47 73.4196 126.312 93.2197C126.214 94.1415 125.436 94.8216 124.531 94.8216Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.528 93.0279C158.094 78.7447 173.412 98.643 172.368 118.884C171.01 145.156 148.437 175.681 124.528 160.969C100.622 175.681 78.0462 145.156 76.6886 118.884C75.6422 98.643 90.9646 78.7447 124.528 93.0279Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.448 89.1728C150.061 92.7741 156.284 105.752 155.606 118.884C154.665 137.087 143.538 157.315 128.841 163.144C151.086 172.001 171.094 143.571 172.368 118.884C173.283 101.22 161.722 83.8163 136.448 89.1728Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.718 89.9631C98.0331 89.9631 93.0717 91.3926 88.9843 94.2492C81.8702 99.2226 77.9433 108.396 78.482 118.788C79.3704 136.009 89.3506 153.889 101.697 160.376C108.993 164.204 116.356 163.891 123.587 159.439C124.167 159.085 124.892 159.085 125.472 159.439C132.703 163.893 140.071 164.202 147.36 160.376C159.706 153.889 169.686 136.012 170.574 118.788C171.111 108.396 167.186 99.2226 160.072 94.2492C151.699 88.3971 139.652 88.5407 125.232 94.6778C124.784 94.8694 124.274 94.8694 123.824 94.6778C116.444 91.5387 109.682 89.9631 103.718 89.9631ZM111.825 166.604C107.865 166.604 103.9 165.588 100.028 163.556C86.6472 156.523 75.8433 137.36 74.8951 118.977C74.294 107.335 78.7909 96.9933 86.925 91.3064C93.4285 86.7592 105.217 83.0693 124.528 91.0765C143.837 83.0717 155.626 86.7568 162.129 91.3064C170.265 96.9909 174.762 107.335 174.161 118.977C173.211 137.36 162.409 156.525 149.031 163.556C140.974 167.784 132.528 167.612 124.528 163.057C120.379 165.421 116.104 166.604 111.825 166.604Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.654 75.3686H146.076C145.085 75.3686 144.28 74.564 144.28 73.5727C144.28 72.5814 145.085 71.7769 146.076 71.7769H155.654C156.646 71.7769 157.45 72.5814 157.45 73.5727C157.45 74.564 156.646 75.3686 155.654 75.3686Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.865 80.1575C149.874 80.1575 149.069 79.353 149.069 78.3617V68.7837C149.069 67.7923 149.874 66.9878 150.865 66.9878C151.857 66.9878 152.661 67.7923 152.661 68.7837V78.3617C152.661 79.353 151.857 80.1575 150.865 80.1575Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.661 59.2058C152.661 60.1971 151.857 61.0017 150.865 61.0017C149.874 61.0017 149.069 60.1971 149.069 59.2058C149.069 58.2145 149.874 57.4099 150.865 57.4099C151.857 57.4099 152.661 58.2145 152.661 59.2058Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.661 52.0222C152.661 53.0135 151.857 53.8181 150.865 53.8181C149.874 53.8181 149.069 53.0135 149.069 52.0222C149.069 51.0309 149.874 50.2263 150.865 50.2263C151.857 50.2263 152.661 51.0309 152.661 52.0222Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.661 40.0473C152.661 41.0387 151.857 41.8432 150.865 41.8432C149.874 41.8432 149.069 41.0387 149.069 40.0473C149.069 39.056 149.874 38.2515 150.865 38.2515C151.857 38.2515 152.661 39.056 152.661 40.0473Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.661 25.6804C152.661 26.6717 151.857 27.4763 150.865 27.4763C149.874 27.4763 149.069 26.6717 149.069 25.6804C149.069 24.6867 149.874 23.8845 150.865 23.8845C151.857 23.8845 152.661 24.6867 152.661 25.6804Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.021 18.1232H160.443C159.452 18.1232 158.647 17.3211 158.647 16.3274C158.647 15.336 159.452 14.5315 160.443 14.5315H170.021C171.013 14.5315 171.817 15.336 171.817 16.3274C171.817 17.3211 171.013 18.1232 170.021 18.1232Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.232 22.9124C164.241 22.9124 163.436 22.1103 163.436 21.1165V11.5385C163.436 10.5472 164.241 9.74268 165.232 9.74268C166.224 9.74268 167.028 10.5472 167.028 11.5385V21.1165C167.028 22.1103 166.224 22.9124 165.232 22.9124Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.436 30.6968C163.436 29.7054 164.241 28.9009 165.232 28.9009C166.226 28.9009 167.028 29.7054 167.028 30.6968C167.028 31.6881 166.226 32.4926 165.232 32.4926C164.241 32.4926 163.436 31.6881 163.436 30.6968Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.436 37.8803C163.436 36.889 164.241 36.0845 165.232 36.0845C166.226 36.0845 167.028 36.889 167.028 37.8803C167.028 38.8717 166.226 39.6762 165.232 39.6762C164.241 39.6762 163.436 38.8717 163.436 37.8803Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.436 49.8552C163.436 48.8639 164.241 48.0593 165.232 48.0593C166.226 48.0593 167.028 48.8639 167.028 49.8552C167.028 50.8465 166.226 51.6511 165.232 51.6511C164.241 51.6511 163.436 50.8465 163.436 49.8552Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.436 64.2221C163.436 63.2308 164.241 62.4263 165.232 62.4263C166.226 62.4263 167.028 63.2308 167.028 64.2221C167.028 65.2135 166.226 66.018 165.232 66.018C164.241 66.018 163.436 65.2135 163.436 64.2221Z"
      fill="#101113"
    />
  </svg>
);

export default Manage;
