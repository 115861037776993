import React from "react";

const StayConnected = () => (
  <svg
    width="168"
    height="159"
    viewBox="0 0 168 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.9625 113.238C53.9625 130.277 40.1515 144.086 23.1147 144.086C6.07795 144.086 -7.73303 130.277 -7.73303 113.238C-7.73303 96.2014 6.07795 82.3904 23.1147 82.3904C40.1515 82.3904 53.9625 96.2014 53.9625 113.238Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168 105.125C168 119.729 156.163 131.566 141.559 131.566C126.957 131.566 115.118 119.729 115.118 105.125C115.118 90.5208 126.957 78.6841 141.559 78.6841C156.163 78.6841 168 90.5208 168 105.125Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.139 44.8111C137.139 69.15 117.407 88.8794 93.0708 88.8794C68.7319 88.8794 49.0026 69.15 49.0026 44.8111C49.0026 20.4723 68.7319 0.74292 93.0708 0.74292C117.407 0.74292 137.139 20.4723 137.139 44.8111Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.9861 134.598V37.6479C64.9861 25.4784 74.8508 15.6138 87.0202 15.6138H42.9454C30.776 15.6138 20.9113 25.4784 20.9113 37.6479V134.598C20.9113 146.767 30.776 156.632 42.9454 156.632H87.0202C74.8508 156.632 64.9861 146.767 64.9861 134.598Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.9861 134.598V37.6479C64.9861 25.4784 74.8508 15.6138 87.0202 15.6138H69.3886C57.2191 15.6138 47.3544 25.4784 47.3544 37.6479V134.598C47.3544 146.767 57.2191 156.632 69.3886 156.632H87.0202C74.8508 156.632 64.9861 146.767 64.9861 134.598Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.9454 17.2663C31.7058 17.2663 22.5639 26.4082 22.5639 37.6478V134.598C22.5639 145.835 31.7058 154.979 42.9454 154.979H74.961C68.0048 150.848 63.3336 143.257 63.3336 134.598V37.6478C63.3336 28.9862 68.0048 21.3977 74.961 17.2663H42.9454ZM87.0202 158.285H42.9454C29.8836 158.285 19.2588 147.658 19.2588 134.598V37.6478C19.2588 24.586 29.8836 13.9612 42.9454 13.9612H87.0202C87.9325 13.9612 88.6728 14.7015 88.6728 15.6137C88.6728 16.526 87.9325 17.2663 87.0202 17.2663C75.7806 17.2663 66.6387 26.4082 66.6387 37.6478V134.598C66.6387 145.835 75.7806 154.979 87.0202 154.979C87.9325 154.979 88.6728 155.72 88.6728 156.632C88.6728 157.544 87.9325 158.285 87.0202 158.285Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.878 125.782C117.925 125.782 109.046 116.9 109.046 105.949V134.598C109.046 146.767 99.1809 156.632 87.0114 156.632H126.677C138.844 156.632 148.711 146.767 148.711 134.598V105.949C148.711 116.9 139.832 125.782 128.878 125.782Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.898 122.435C137.322 124.16 134.319 125.293 131.084 125.65V134.598C131.084 146.767 121.217 156.632 109.05 156.632H126.677C138.844 156.632 148.711 146.767 148.711 134.598V105.949C148.711 112.826 145.21 118.879 139.898 122.435Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.0707 154.979H126.677C137.915 154.979 147.059 145.835 147.059 134.598V117.387C143.249 123.42 136.524 127.435 128.878 127.435C121.233 127.435 114.508 123.42 110.7 117.387V134.598C110.7 143.257 106.027 150.848 99.0707 154.979ZM126.677 158.285H87.0136C86.0992 158.285 85.3611 157.544 85.3611 156.632C85.3611 155.72 86.0992 154.979 87.0136 154.979C98.251 154.979 107.395 145.835 107.395 134.598V105.949C107.395 105.037 108.133 104.297 109.048 104.297C109.96 104.297 110.7 105.037 110.7 105.949C110.7 115.975 118.855 124.13 128.878 124.13C138.902 124.13 147.059 115.975 147.059 105.949C147.059 105.037 147.799 104.297 148.711 104.297C149.624 104.297 150.364 105.037 150.364 105.949V134.598C150.364 147.658 139.737 158.285 126.677 158.285Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.677 15.6138H87.0114C99.1809 15.6138 109.046 25.4784 109.046 37.6479V105.949C109.046 116.9 117.925 125.782 128.878 125.782C139.832 125.782 148.711 116.9 148.711 105.949V37.6479C148.711 25.4784 138.844 15.6138 126.677 15.6138Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.711 37.6479C148.711 25.4784 138.844 15.6138 126.677 15.6138H125.908C127.786 18.8572 128.878 22.6118 128.878 26.6308V94.9322C128.878 105.196 136.679 113.639 146.673 114.659C147.964 112.028 148.711 109.078 148.711 105.949V37.6479Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.0707 17.2663C106.027 21.3977 110.7 28.9862 110.7 37.6478V105.949C110.7 115.975 118.855 124.13 128.878 124.13C138.902 124.13 147.059 115.975 147.059 105.949V37.6478C147.059 26.4082 137.915 17.2663 126.677 17.2663H99.0707ZM128.878 127.435C117.033 127.435 107.395 117.797 107.395 105.949V37.6478C107.395 26.4082 98.251 17.2663 87.0136 17.2663C86.0992 17.2663 85.3611 16.526 85.3611 15.6137C85.3611 14.7015 86.0992 13.9612 87.0136 13.9612H126.677C139.737 13.9612 150.364 24.586 150.364 37.6478V105.949C150.364 117.797 140.726 127.435 128.878 127.435Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.435 139.28H93.6217C92.7095 139.28 91.9691 138.54 91.9691 137.628C91.9691 136.715 92.7095 135.975 93.6217 135.975H102.435C103.348 135.975 104.088 136.715 104.088 137.628C104.088 138.54 103.348 139.28 102.435 139.28Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.0285 143.687C97.1163 143.687 96.3759 142.947 96.3759 142.035V133.221C96.3759 132.309 97.1163 131.568 98.0285 131.568C98.9407 131.568 99.681 132.309 99.681 133.221V142.035C99.681 142.947 98.9407 143.687 98.0285 143.687Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.681 124.407C99.681 125.319 98.9407 126.06 98.0285 126.06C97.1163 126.06 96.3759 125.319 96.3759 124.407C96.3759 123.495 97.1163 122.755 98.0285 122.755C98.9407 122.755 99.681 123.495 99.681 124.407Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.681 117.797C99.681 118.709 98.9407 119.45 98.0285 119.45C97.1163 119.45 96.3759 118.709 96.3759 117.797C96.3759 116.885 97.1163 116.145 98.0285 116.145C98.9407 116.145 99.681 116.885 99.681 117.797Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.681 106.778C99.681 107.69 98.9407 108.43 98.0285 108.43C97.1163 108.43 96.3759 107.69 96.3759 106.778C96.3759 105.865 97.1163 105.125 98.0285 105.125C98.9407 105.125 99.681 105.865 99.681 106.778Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.681 93.5549C99.681 94.4671 98.9407 95.2075 98.0285 95.2075C97.1163 95.2075 96.3759 94.4671 96.3759 93.5549C96.3759 92.6427 97.1163 91.9023 98.0285 91.9023C98.9407 91.9023 99.681 92.6427 99.681 93.5549Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.9773 127.988H20.9091C13.607 127.988 7.68864 122.069 7.68864 114.767V57.4785C7.68864 50.1764 13.607 44.2581 20.9091 44.2581H64.9773C72.2772 44.2581 78.1978 50.1764 78.1978 57.4785V114.767C78.1978 122.069 72.2772 127.988 64.9773 127.988Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.9773 127.99H20.9091V119.174C20.9091 87.5332 46.5568 61.8877 78.1978 61.8877V114.77C78.1978 122.069 72.2794 127.99 64.9773 127.99Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9091 45.9106C14.5302 45.9106 9.3412 51.0996 9.3412 57.4785V114.767C9.3412 121.144 14.5302 126.335 20.9091 126.335H64.9773C71.3562 126.335 76.5452 121.144 76.5452 114.767V57.4785C76.5452 51.0996 71.3562 45.9106 64.9773 45.9106H20.9091ZM64.9773 129.64H20.9091C12.7058 129.64 6.03609 122.968 6.03609 114.767V57.4785C6.03609 49.2752 12.7058 42.6055 20.9091 42.6055H64.9773C73.1784 42.6055 79.8503 49.2752 79.8503 57.4785V114.767C79.8503 122.968 73.1784 129.64 64.9773 129.64Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.8124 101.547H78.2022V70.699H84.8124C87.245 70.699 89.2192 72.671 89.2192 75.1058V97.1399C89.2192 99.5747 87.245 101.547 84.8124 101.547Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.8124 101.547H83.7107V85.0212C83.7107 81.9783 86.1763 79.5127 89.2193 79.5127V97.14C89.2193 99.5748 87.245 101.547 84.8124 101.547Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.8548 99.8941H84.8124C86.3306 99.8941 87.5667 98.658 87.5667 97.1399V75.1058C87.5667 73.5854 86.3306 72.3515 84.8124 72.3515H79.8548V99.8941ZM84.8124 103.199H78.2022C77.2878 103.199 76.5496 102.459 76.5496 101.547V70.6989C76.5496 69.7867 77.2878 69.0464 78.2022 69.0464H84.8124C88.1528 69.0464 90.8718 71.7632 90.8718 75.1058V97.1399C90.8718 100.48 88.1528 103.199 84.8124 103.199Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.489 105.949C135.489 102.3 132.53 99.3389 128.878 99.3389C125.227 99.3389 122.268 102.3 122.268 105.949C122.268 109.602 125.227 112.559 128.878 112.559C132.53 112.559 135.489 109.602 135.489 105.949Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.489 105.949C135.489 105.286 135.361 104.66 135.178 104.054C134.574 103.874 133.948 103.748 133.285 103.748C129.634 103.748 126.675 106.707 126.675 110.358C126.675 111.022 126.803 111.647 126.984 112.251C127.587 112.434 128.215 112.559 128.878 112.559C132.529 112.559 135.489 109.603 135.489 105.949Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.878 100.992C126.144 100.992 123.921 103.217 123.921 105.949C123.921 108.684 126.144 110.907 128.878 110.907C131.613 110.907 133.836 108.684 133.836 105.949C133.836 103.217 131.613 100.992 128.878 100.992ZM128.878 114.212C124.322 114.212 120.616 110.506 120.616 105.949C120.616 101.393 124.322 97.6865 128.878 97.6865C133.435 97.6865 137.141 101.393 137.141 105.949C137.141 110.506 133.435 114.212 128.878 114.212Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.489 81.7118C135.489 78.0607 132.53 75.1016 128.878 75.1016C125.227 75.1016 122.268 78.0607 122.268 81.7118C122.268 85.3606 125.227 88.322 128.878 88.322C132.53 88.322 135.489 85.3606 135.489 81.7118Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.489 81.7117C135.489 81.0485 135.361 80.4205 135.178 79.8168C134.574 79.6339 133.948 79.5083 133.285 79.5083C129.634 79.5083 126.675 82.4675 126.675 86.1185C126.675 86.7818 126.803 87.4097 126.984 88.0113C127.587 88.1919 128.215 88.3219 128.878 88.3219C132.529 88.3219 135.489 85.3606 135.489 81.7117Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.878 76.7541C126.144 76.7541 123.921 78.9773 123.921 81.7118C123.921 84.444 126.144 86.6694 128.878 86.6694C131.613 86.6694 133.836 84.444 133.836 81.7118C133.836 78.9773 131.613 76.7541 128.878 76.7541ZM128.878 89.9746C124.322 89.9746 120.616 86.2662 120.616 81.7118C120.616 77.1529 124.322 73.449 128.878 73.449C133.435 73.449 137.141 77.1529 137.141 81.7118C137.141 86.2662 133.435 89.9746 128.878 89.9746Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.489 57.4696C135.489 53.8208 132.53 50.8594 128.878 50.8594C125.227 50.8594 122.268 53.8208 122.268 57.4696C122.268 61.1207 125.227 64.0798 128.878 64.0798C132.53 64.0798 135.489 61.1207 135.489 57.4696Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.675 61.8766C126.675 62.542 126.803 63.1678 126.984 63.7715C127.587 63.9544 128.215 64.08 128.878 64.08C132.529 64.08 135.489 61.1208 135.489 57.4698C135.489 56.8065 135.361 56.1808 135.178 55.5748C134.574 55.3942 133.948 55.2664 133.285 55.2664C129.634 55.2664 126.675 58.2277 126.675 61.8766Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.878 52.5121C126.144 52.5121 123.921 54.7376 123.921 57.4698C123.921 60.2043 126.144 62.4275 128.878 62.4275C131.613 62.4275 133.836 60.2043 133.836 57.4698C133.836 54.7376 131.613 52.5121 128.878 52.5121ZM128.878 65.7326C124.322 65.7326 120.616 62.0265 120.616 57.4698C120.616 52.9132 124.322 49.207 128.878 49.207C133.435 49.207 137.141 52.9132 137.141 57.4698C137.141 62.0265 133.435 65.7326 128.878 65.7326Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.9149 121.631C43.8731 121.631 43.8312 121.631 43.7893 121.626C43.2054 121.582 42.6876 121.23 42.4298 120.701L34.2618 103.944L30.3684 112.064C30.0929 112.637 29.5134 113.002 28.8766 113.002H16.5023C15.5901 113.002 14.8497 112.262 14.8497 111.35C14.8497 110.438 15.5901 109.697 16.5023 109.697H27.8366L32.7591 99.4337C33.0345 98.8608 33.6162 98.473 34.2486 98.495C34.8809 98.495 35.4582 98.8564 35.7359 99.4249L44.1661 116.719L53.2265 103.669C53.5306 103.232 54.0242 102.968 54.5552 102.957C55.084 102.919 55.5886 103.195 55.9059 103.62L60.4603 109.697H69.3842C70.2964 109.697 71.0367 110.438 71.0367 111.35C71.0367 112.262 70.2964 113.002 69.3842 113.002H59.6341C59.1141 113.002 58.6249 112.758 58.312 112.339L54.6367 107.436L45.2744 120.921C44.9637 121.369 44.4548 121.631 43.9149 121.631Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.7904 67.2419C65.7904 60.6316 60.381 55.2773 53.7113 55.2773C49.2802 55.2773 45.4176 57.6482 43.3156 61.1715C41.2135 57.6482 37.351 55.2773 32.9199 55.2773C26.2502 55.2773 20.8408 60.6316 20.8408 67.2419C20.8408 81.3371 43.3156 94.9387 43.3156 94.9387C43.3156 94.9387 65.7904 81.3371 65.7904 67.2419Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.7113 55.2773C52.7814 55.2773 51.8869 55.4029 51.0187 55.599C53.5659 57.7936 55.2118 60.9886 55.2118 64.5956C55.2118 75.1609 42.5885 85.4464 36.2647 89.937C40.1868 93.0416 43.3156 94.9387 43.3156 94.9387C43.3156 94.9387 65.7904 81.3371 65.7904 67.2419C65.7904 60.6316 60.381 55.2773 53.7113 55.2773Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9199 56.9299C27.169 56.9299 22.4934 61.5548 22.4934 67.2418C22.4934 78.7547 39.2922 90.3666 43.3156 92.9821C47.3346 90.3666 64.1378 78.7414 64.1378 67.2418C64.1378 61.5548 59.46 56.9299 53.7113 56.9299C50.047 56.9299 46.6075 58.8799 44.7346 62.0175C44.1397 63.0157 42.4915 63.0157 41.8966 62.0175C40.0237 58.8799 36.5842 56.9299 32.9199 56.9299ZM43.3156 96.5913C43.0203 96.5913 42.7229 96.5119 42.4607 96.3511C41.511 95.7782 19.1882 82.0994 19.1882 67.2418C19.1882 59.7326 25.3468 53.6248 32.9199 53.6248C36.9257 53.6248 40.7288 55.3963 43.3156 58.3687C45.9024 55.3963 49.7055 53.6248 53.7113 53.6248C61.2822 53.6248 67.443 59.7326 67.443 67.2418C67.443 82.0994 45.1202 95.7782 44.1705 96.3511C43.9083 96.5119 43.6109 96.5913 43.3156 96.5913Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.808 34.7812H75.9944C75.0822 34.7812 74.3418 34.0408 74.3418 33.1286C74.3418 32.2164 75.0822 31.4761 75.9944 31.4761H84.808C85.7202 31.4761 86.4606 32.2164 86.4606 33.1286C86.4606 34.0408 85.7202 34.7812 84.808 34.7812Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.4012 39.1881C79.489 39.1881 78.7486 38.4478 78.7486 37.5355V28.7219C78.7486 27.8097 79.489 27.0693 80.4012 27.0693C81.3134 27.0693 82.0538 27.8097 82.0538 28.7219V37.5355C82.0538 38.4478 81.3134 39.1881 80.4012 39.1881Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.8251 54.614H87.0114C86.0992 54.614 85.3589 53.8736 85.3589 52.9614C85.3589 52.0492 86.0992 51.3088 87.0114 51.3088H95.8251C96.7373 51.3088 97.4776 52.0492 97.4776 52.9614C97.4776 53.8736 96.7373 54.614 95.8251 54.614Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.4183 59.0209C90.506 59.0209 89.7657 58.2805 89.7657 57.3683V48.5547C89.7657 47.6424 90.506 46.9021 91.4183 46.9021C92.3305 46.9021 93.0708 47.6424 93.0708 48.5547V57.3683C93.0708 58.2805 92.3305 59.0209 91.4183 59.0209Z"
      fill="#101113"
    />
  </svg>
);

export default StayConnected;
