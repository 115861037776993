import React from "react";

const Organize = () => (
  <svg
    width="165"
    height="175"
    viewBox="0 0 165 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.522 30.9022C164.522 47.9691 150.779 61.8044 133.826 61.8044C116.873 61.8044 103.13 47.9691 103.13 30.9022C103.13 13.8376 116.873 0 133.826 0C150.779 0 164.522 13.8376 164.522 30.9022Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.875 143.481C162.875 158.109 151.095 169.969 136.564 169.969C122.032 169.969 110.254 158.109 110.254 143.481C110.254 128.853 122.032 116.994 136.564 116.994C151.095 116.994 162.875 128.853 162.875 143.481Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.9221 130.489C87.9221 154.871 68.2898 174.635 44.0706 174.635C19.8536 174.635 0.219116 154.871 0.219116 130.489C0.219116 106.107 19.8536 86.343 44.0706 86.343C68.2898 86.343 87.9221 106.107 87.9221 130.489Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.176 156.584H24.0875C20.4544 156.584 17.5098 153.619 17.5098 149.962V21.9295C17.5098 18.272 20.4544 15.3076 24.0875 15.3076H116.176C119.809 15.3076 122.753 18.272 122.753 21.9295V149.962C122.753 153.619 119.809 156.584 116.176 156.584Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.6351 141.128H17.5098V21.9361C17.5098 18.2764 20.4544 15.3142 24.0875 15.3142H105.213V134.506C105.213 138.164 102.266 141.128 98.6351 141.128Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.646 104.71H54.7901C53.5776 104.71 52.5976 105.699 52.5976 106.917V117.954C52.5976 119.17 53.5776 120.161 54.7901 120.161H155.646C156.859 120.161 157.839 119.17 157.839 117.954V106.917C157.839 105.699 156.859 104.71 155.646 104.71Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.99 104.71H54.7901C53.5776 104.71 52.5976 105.699 52.5976 106.917V112.436H109.605C112.025 112.436 113.99 110.458 113.99 108.021V104.71Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.7902 106.365C54.4854 106.365 54.242 106.613 54.242 106.917V117.954C54.242 118.256 54.4854 118.506 54.7902 118.506H155.646C155.949 118.506 156.195 118.256 156.195 117.954V106.917C156.195 106.613 155.949 106.365 155.646 106.365H54.7902ZM155.646 121.817H54.7902C52.6743 121.817 50.9532 120.084 50.9532 117.954V106.917C50.9532 104.787 52.6743 103.054 54.7902 103.054H155.646C157.762 103.054 159.483 104.787 159.483 106.917V117.954C159.483 120.084 157.762 121.817 155.646 121.817Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.819 134.509H146.878V90.3625H134.819V134.509Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.76 143.338H134.819V81.5332H122.76V143.338Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.556 134.509H75.6152V90.3625H63.556V134.509Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.6174 143.338H87.6744V81.5332H75.6174V143.338Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.849 90.3625H134.819V125.679H138.656C139.869 125.679 140.849 124.688 140.849 123.472V90.3625Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.79 81.5398H122.76V130.1H126.597C127.809 130.1 128.79 129.109 128.79 127.893V81.5398Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.5856 90.3625H63.556V125.679H67.393C68.6055 125.679 69.5856 124.688 69.5856 123.472V90.3625Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.647 81.5332H75.6174V130.1H79.4544C80.6647 130.1 81.647 129.109 81.647 127.893V81.5332Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.466 132.853H145.236V92.018H136.466V132.853ZM146.88 136.164H134.821C133.914 136.164 133.177 135.425 133.177 134.509V90.3625C133.177 89.4487 133.914 88.707 134.821 88.707H146.88C147.788 88.707 148.525 89.4487 148.525 90.3625V134.509C148.525 135.425 147.788 136.164 146.88 136.164Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.407 141.682H133.175V83.1889H124.407V141.682ZM134.819 144.993H122.762C121.854 144.993 121.118 144.254 121.118 143.338V81.5334C121.118 80.6196 121.854 79.8779 122.762 79.8779H134.819C135.727 79.8779 136.464 80.6196 136.464 81.5334V143.338C136.464 144.254 135.727 144.993 134.819 144.993Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.2027 132.853H73.973V92.018H65.2027V132.853ZM75.6174 136.164H63.5582C62.6505 136.164 61.9138 135.425 61.9138 134.509V90.3625C61.9138 89.4487 62.6505 88.707 63.5582 88.707H75.6174C76.5251 88.707 77.2618 89.4487 77.2618 90.3625V134.509C77.2618 135.425 76.5251 136.164 75.6174 136.164Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.2618 141.682H86.0321V83.1889H77.2618V141.682ZM87.6766 144.993H75.6174C74.7097 144.993 73.973 144.254 73.973 143.338V81.5334C73.973 80.6196 74.7097 79.8779 75.6174 79.8779H87.6766C88.5843 79.8779 89.321 80.6196 89.321 81.5334V143.338C89.321 144.254 88.5843 144.993 87.6766 144.993Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0875 16.9696C21.3687 16.9696 19.1542 19.1968 19.1542 21.9361V149.96C19.1542 152.699 21.3687 154.926 24.0875 154.926H116.176C118.892 154.926 121.109 152.699 121.109 149.96V21.9361C121.109 19.1968 118.892 16.9696 116.176 16.9696H24.0875ZM116.176 158.237H24.0875C19.5555 158.237 15.8654 154.524 15.8654 149.96V21.9361C15.8654 17.3714 19.5555 13.6587 24.0875 13.6587H116.176C120.708 13.6587 124.398 17.3714 124.398 21.9361V149.96C124.398 154.524 120.708 158.237 116.176 158.237Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.8647 32.966H50.3984C46.7653 32.966 43.8206 30.0016 43.8206 26.3441V15.3076H96.4425V26.3441C96.4425 30.0016 93.4956 32.966 89.8647 32.966Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.0922 24.1368H43.8206V15.3076H85.4774V19.7222C85.4774 22.1613 83.515 24.1368 81.0922 24.1368Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.4651 16.9631V26.3441C45.4651 29.0833 47.6796 31.3105 50.3984 31.3105H89.8648C92.5814 31.3105 94.798 29.0833 94.798 26.3441V16.9631H45.4651ZM89.8648 34.6215H50.3984C45.8663 34.6215 42.1762 30.9088 42.1762 26.3441V15.3076C42.1762 14.3938 42.9129 13.6521 43.8207 13.6521H96.4425C97.3502 13.6521 98.0869 14.3938 98.0869 15.3076V26.3441C98.0869 30.9088 94.3968 34.6215 89.8648 34.6215Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.607 50.0815H86.5847C85.677 50.0815 84.9402 49.3398 84.9402 48.426C84.9402 47.5122 85.677 46.7705 86.5847 46.7705H109.607C110.514 46.7705 111.251 47.5122 111.251 48.426C111.251 49.3398 110.514 50.0815 109.607 50.0815Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.8122 50.0815H52.5954C51.6877 50.0815 50.951 49.3398 50.951 48.426C50.951 47.5122 51.6877 46.7705 52.5954 46.7705H77.8122C78.7199 46.7705 79.4566 47.5122 79.4566 48.426C79.4566 49.3398 78.7199 50.0815 77.8122 50.0815Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.8713 58.9108H72.3285C71.4208 58.9108 70.6841 58.1692 70.6841 57.2553C70.6841 56.3415 71.4208 55.5999 72.3285 55.5999H89.8713C90.7791 55.5999 91.5158 56.3415 91.5158 57.2553C91.5158 58.1692 90.7791 58.9108 89.8713 58.9108Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.5954 58.9108C51.6877 58.9108 50.951 58.1692 50.951 57.2553C50.951 56.3415 51.6877 55.5999 52.5954 55.5999H63.5583C64.466 55.5999 65.2027 56.3415 65.2027 57.2553C65.2027 58.1692 64.466 58.9108 63.5583 58.9108H52.5954Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.51 74.3639H84.3877C83.48 74.3639 82.7433 73.6201 82.7433 72.7085C82.7433 71.7924 83.48 71.053 84.3877 71.053H108.51C109.418 71.053 110.155 71.7924 110.155 72.7085C110.155 73.6201 109.418 74.3639 108.51 74.3639Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.6174 74.3639H52.5954C51.6877 74.3639 50.951 73.6201 50.951 72.7085C50.951 71.7924 51.6877 71.053 52.5954 71.053H75.6174C76.5252 71.053 77.2619 71.7924 77.2619 72.7085C77.2619 73.6201 76.5252 74.3639 75.6174 74.3639Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.8471 83.1933H52.5954C51.6877 83.1933 50.951 82.4516 50.951 81.5378C50.951 80.624 51.6877 79.8823 52.5954 79.8823H66.8471C67.7548 79.8823 68.4916 80.624 68.4916 81.5378C68.4916 82.4516 67.7548 83.1933 66.8471 83.1933Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.454 149.406H144.683C143.776 149.406 143.039 148.666 143.039 147.75C143.039 146.836 143.776 146.095 144.683 146.095H153.454C154.362 146.095 155.098 146.836 155.098 147.75C155.098 148.666 154.362 149.406 153.454 149.406Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.069 153.82C148.161 153.82 147.424 153.081 147.424 152.165V143.336C147.424 142.422 148.161 141.68 149.069 141.68C149.976 141.68 150.713 142.422 150.713 143.336V152.165C150.713 153.081 149.976 153.82 149.069 153.82Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6302 52.8407C41.6302 56.4959 38.6834 59.4626 35.0525 59.4626C31.4194 59.4626 28.4726 56.4959 28.4726 52.8407C28.4726 49.1809 31.4194 46.2188 35.0525 46.2188C38.6834 46.2188 41.6302 49.1809 41.6302 52.8407Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6302 77.1231C41.6302 80.7806 38.6834 83.7472 35.0525 83.7472C31.4194 83.7472 28.4726 80.7806 28.4726 77.1231C28.4726 73.4634 31.4194 70.499 35.0525 70.499C38.6834 70.499 41.6302 73.4634 41.6302 77.1231Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.86 57.2552C32.86 57.9174 32.9872 58.5487 33.1669 59.1513C33.7677 59.3345 34.3904 59.4625 35.0526 59.4625C38.6835 59.4625 41.6303 56.4959 41.6303 52.8406C41.6303 52.1762 41.5031 51.5471 41.3211 50.9423C40.7226 50.7591 40.0977 50.6333 39.4377 50.6333C35.8046 50.6333 32.86 53.5977 32.86 57.2552Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6303 77.1231C41.6303 76.4587 41.5031 75.8296 41.3211 75.2226C40.7226 75.0416 40.0977 74.9158 39.4377 74.9158C35.8046 74.9158 32.86 77.878 32.86 81.5377C32.86 82.2021 32.9872 82.8312 33.1669 83.436C33.7677 83.6169 34.3904 83.7472 35.0526 83.7472C38.6835 83.7472 41.6303 80.7806 41.6303 77.1231Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0525 47.8742C32.3337 47.8742 30.1192 50.1014 30.1192 52.8406C30.1192 55.5777 32.3337 57.807 35.0525 57.807C37.7713 57.807 39.9858 55.5777 39.9858 52.8406C39.9858 50.1014 37.7713 47.8742 35.0525 47.8742ZM35.0525 61.118C30.5205 61.118 26.8304 57.4053 26.8304 52.8406C26.8304 48.2737 30.5205 44.5632 35.0525 44.5632C39.5846 44.5632 43.2747 48.2737 43.2747 52.8406C43.2747 57.4053 39.5846 61.118 35.0525 61.118Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0525 72.1567C32.3337 72.1567 30.1192 74.3816 30.1192 77.1231C30.1192 79.8623 32.3337 82.0895 35.0525 82.0895C37.7713 82.0895 39.9858 79.8623 39.9858 77.1231C39.9858 74.3816 37.7713 72.1567 35.0525 72.1567ZM35.0525 85.4005C30.5205 85.4005 26.8304 81.6856 26.8304 77.1231C26.8304 72.5584 30.5205 68.8457 35.0525 68.8457C39.5846 68.8457 43.2747 72.5584 43.2747 77.1231C43.2747 81.6856 39.5846 85.4005 35.0525 85.4005Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6302 101.406C41.6302 105.063 38.6834 108.027 35.0525 108.027C31.4194 108.027 28.4726 105.063 28.4726 101.406C28.4726 97.7481 31.4194 94.7837 35.0525 94.7837C38.6834 94.7837 41.6302 97.7481 41.6302 101.406Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6302 125.688C41.6302 129.346 38.6834 132.312 35.0525 132.312C31.4194 132.312 28.4726 129.346 28.4726 125.688C28.4726 122.029 31.4194 119.066 35.0525 119.066C38.6834 119.066 41.6302 122.029 41.6302 125.688Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.86 105.82C32.86 106.482 32.9872 107.114 33.1669 107.718C33.7677 107.902 34.3904 108.027 35.0526 108.027C38.6835 108.027 41.6303 105.063 41.6303 101.406C41.6303 100.741 41.5031 100.112 41.3211 99.5073C40.7226 99.3241 40.0977 99.1982 39.4377 99.1982C35.8046 99.1982 32.86 102.163 32.86 105.82Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6303 125.688C41.6303 125.024 41.5031 124.397 41.3211 123.788C40.7226 123.607 40.0977 123.481 39.4377 123.481C35.8046 123.481 32.86 126.443 32.86 130.105C32.86 130.767 32.9872 131.396 33.1669 132.001C33.7677 132.182 34.3904 132.312 35.0526 132.312C38.6835 132.312 41.6303 129.346 41.6303 125.688Z"
      fill="#7CDEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0525 96.4391C32.3337 96.4391 30.1192 98.6663 30.1192 101.406C30.1192 104.143 32.3337 106.372 35.0525 106.372C37.7713 106.372 39.9858 104.143 39.9858 101.406C39.9858 98.6663 37.7713 96.4391 35.0525 96.4391ZM35.0525 109.683C30.5205 109.683 26.8304 105.97 26.8304 101.406C26.8304 96.8386 30.5205 93.1282 35.0525 93.1282C39.5846 93.1282 43.2747 96.8386 43.2747 101.406C43.2747 105.97 39.5846 109.683 35.0525 109.683Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0525 120.719C32.3337 120.719 30.1192 122.949 30.1192 125.688C30.1192 128.427 32.3337 130.657 35.0525 130.657C37.7713 130.657 39.9858 128.427 39.9858 125.688C39.9858 122.949 37.7713 120.719 35.0525 120.719ZM35.0525 133.968C30.5205 133.968 26.8304 130.253 26.8304 125.688C26.8304 121.123 30.5205 117.408 35.0525 117.408C39.5846 117.408 43.2747 121.123 43.2747 125.688C43.2747 130.253 39.5846 133.968 35.0525 133.968Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.839 80.4274H149.069C148.161 80.4274 147.424 79.688 147.424 78.7719C147.424 77.8581 148.161 77.1165 149.069 77.1165H157.839C158.747 77.1165 159.483 77.8581 159.483 78.7719C159.483 79.688 158.747 80.4274 157.839 80.4274Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.454 84.8421C152.546 84.8421 151.809 84.1026 151.809 83.1866V74.3574C151.809 73.4436 152.546 72.7019 153.454 72.7019C154.362 72.7019 155.098 73.4436 155.098 74.3574V83.1866C155.098 84.1026 154.362 84.8421 153.454 84.8421Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.098 65.528C155.098 66.4419 154.362 67.1835 153.454 67.1835C152.546 67.1835 151.809 66.4419 151.809 65.528C151.809 64.6142 152.546 63.8726 153.454 63.8726C154.362 63.8726 155.098 64.6142 155.098 65.528Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.098 58.9062C155.098 59.82 154.362 60.5617 153.454 60.5617C152.546 60.5617 151.809 59.82 151.809 58.9062C151.809 57.9924 152.546 57.2507 153.454 57.2507C154.362 57.2507 155.098 57.9924 155.098 58.9062Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.098 47.8698C155.098 48.7837 154.362 49.5253 153.454 49.5253C152.546 49.5253 151.809 48.7837 151.809 47.8698C151.809 46.956 152.546 46.2144 153.454 46.2144C154.362 46.2144 155.098 46.956 155.098 47.8698Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.098 34.6237C155.098 35.5376 154.362 36.2792 153.454 36.2792C152.546 36.2792 151.809 35.5376 151.809 34.6237C151.809 33.7099 152.546 32.9683 153.454 32.9683C154.362 32.9683 155.098 33.7099 155.098 34.6237Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.491 21.3776H133.721C132.813 21.3776 132.076 20.6359 132.076 19.7221C132.076 18.8083 132.813 18.0667 133.721 18.0667H142.491C143.399 18.0667 144.135 18.8083 144.135 19.7221C144.135 20.6359 143.399 21.3776 142.491 21.3776Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.106 25.7923C137.198 25.7923 136.461 25.0506 136.461 24.1368V15.3076C136.461 14.3938 137.198 13.6521 138.106 13.6521C139.014 13.6521 139.75 14.3938 139.75 15.3076V24.1368C139.75 25.0506 139.014 25.7923 138.106 25.7923Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.459 32.966C136.459 32.0522 137.196 31.3105 138.104 31.3105C139.011 31.3105 139.748 32.0522 139.748 32.966C139.748 33.8821 139.011 34.6215 138.104 34.6215C137.196 34.6215 136.459 33.8821 136.459 32.966Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.459 39.59C136.459 38.6762 137.196 37.9346 138.104 37.9346C139.011 37.9346 139.748 38.6762 139.748 39.59C139.748 40.5039 139.011 41.2455 138.104 41.2455C137.196 41.2455 136.459 40.5039 136.459 39.59Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.459 50.6289C136.459 49.715 137.196 48.9734 138.104 48.9734C139.011 48.9734 139.748 49.715 139.748 50.6289C139.748 51.5427 139.011 52.2843 138.104 52.2843C137.196 52.2843 136.459 51.5427 136.459 50.6289Z"
      fill="#101113"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.459 63.8728C136.459 62.9589 137.196 62.2173 138.104 62.2173C139.011 62.2173 139.748 62.9589 139.748 63.8728C139.748 64.7866 139.011 65.5282 138.104 65.5282C137.196 65.5282 136.459 64.7866 136.459 63.8728Z"
      fill="#101113"
    />
  </svg>
);

export default Organize;
